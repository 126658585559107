import React from 'react';
import axios from 'axios';
import './UploadFiles.css'
import {Line} from 'rc-progress';
import { Upload } from 'react-feather';
import Slider from '@material-ui/core/Slider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClipLoader from "react-spinners/ClipLoader";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import { v4 as uuidv4 } from 'uuid';

export default class UploadFiles extends React.Component {
    constructor(props) {
        super(props);
        this.advancedOptionsDefault = {
            smoothingDepth: 5,
            minLayerDepth: 2,
        }

        this.state = {
            selectedFiles: null,
            uploadTotal: 0,
            uploadStatus: 0,
            uploadText: "Last opp filer",
            uploadFileErrors: [],
            smoothingDepth: this.advancedOptionsDefault.smoothingDepth,
            minLayerDepth: this.advancedOptionsDefault.minLayerDepth,
        }
    }
    
    onChangeHandler = event => {
        let legalFiles = []
        let numIllegalFiles = 0;
        event.target.files.forEach(file => {
            if((file.name.toLowerCase().lastIndexOf(".snd")!==-1
            || file.name.toLowerCase().lastIndexOf(".tlk")!==-1
            || file.name.toLowerCase().lastIndexOf(".prv")!==-1)
            && !file.name.toLowerCase().includes("cptu")
            && !file.name.toLowerCase().includes("_cpt.cpt")
            && !file.name.toLowerCase().includes("_pr.snd")
            && !file.name.toLowerCase().includes("-cpt.cpt")
            && !file.name.toLowerCase().includes("-pr.snd")
            ){
                legalFiles.push(file);
            }
            else{
                numIllegalFiles += 1;
            }
        })

        let uploadFileErrors = [];
        if(numIllegalFiles>0){
            uploadFileErrors.push(numIllegalFiles + " filer ignorert (ugyldig filnavn)");
        }

        let uploadText = "Last opp filer"
        if (legalFiles.length === 1) {
            uploadText = legalFiles[0].name
        }
        //Multiple files uploaded
        else if (legalFiles.length > 1) {
            uploadText = legalFiles.length + " filer"
        }

        this.setState({
            selectedFiles: legalFiles,
            uploadTotal: legalFiles.length,
            uploadFileErrors: uploadFileErrors,
            uploadText: uploadText,
            uploadStatus: 0
        });
    };



    handleNewResponse = (boreholeId, newBorhole) => {
        let boreholeList = this.props.boreholeList;
        boreholeList[boreholeId] = newBorhole;
        this.props.onUploadFile(boreholeList);
    };

    handleNewResponseDynamicData = (newDynamicData) => {
        let newDynamicDataList = [...this.props.dynamicDataList, newDynamicData];
        this.props.onDynamicDataListChange(newDynamicDataList);
    }

    handleLoadingChange = (newLoading) =>{
        this.props.onLoadingChange(newLoading)
    }


    findMaxPressure = (staticData) => {
        let maxPressure = 1;

        for(var i = 0; i < staticData.length; i++) {
            if(staticData[i]["Trykk"] > maxPressure) {
                maxPressure = staticData[i]["Trykk"];
            }
        }
        
        return maxPressure;
    }

    makeDynamicData = (data, maxPressure) => {
        let maxDepth = data[0].max_depth;

        data.push({type: 'Slutt', start: maxDepth, stop: maxDepth});

        data[0]["draggable"] = false;
        data[0]["deleteButtonWidth"] = 0;
        data[0]["pressure"] = maxPressure;
        data[0]["fillColor"] = this.props.colorConfig[data[0]["type"]];
        data[0]["bulletDisabled"] = false;

        for(var j = 1; j < data.length - 1; j++) {
            data[j]["fillColor"] = this.props.colorConfig[data[j]["type"]];
            data[j]["draggable"] = true;
            data[j]["bulletDisabled"] = false;
            data[j]["deleteButtonWidth"] = 25;
            data[j]["pressure"] = maxPressure;
        }

        data[data.length - 1]["draggable"] = false;
        data[data.length - 1]["hideButtons"] = true;
        data[data.length - 1]["bulletDisabled"] = true;
        data[data.length - 1]["pressure"] = maxPressure; 

        return data;
    }


    onClickHandler = () => {
        if (this.state.selectedFiles !== null && this.state.selectedFiles.length > 0) {
            let config = {
                headers: {
                    "apikey": "wGn]Q^m,<.--cc*#-BYe?@V},VKXc8bp6e$",
                    "user": this.props.accountInfo.account.userName,
                    "Access-Control-Allow-Origin": "*",
                    "plot_proba": 0,
                    "smoothing_depth_layers": this.state.smoothingDepth,
                    "min_depth_layer": this.state.minLayerDepth,
                    "writeWhere": this.props.writeWhereOnChange,
                    "from_geotolk": 1
                }
            }
            this.handleLoadingChange(true)
            this.setState({
                uploadStatus: 0
            })

            let boreholes = {};

            for (let i = 0; i < this.state.selectedFiles.length; i++) {
                let file = this.state.selectedFiles[i];
                let boreholeName = file.name.slice(0,-4);

                if (boreholeName.toLowerCase().includes("_cpt")){
                    boreholeName = boreholeName.replace("_cpt", "");
                    boreholeName = boreholeName.replace("_CPT", "");
                }
                if (boreholeName.toLowerCase().includes("_pr")){
                    boreholeName = boreholeName.replace("_pr", "");
                    boreholeName = boreholeName.replace("_PR", "");
                }                
                if (boreholeName.toLowerCase().includes("-pr")){
                    boreholeName = boreholeName.replace("-pr", "");
                    boreholeName = boreholeName.replace("-PR", "");
                }                
                if (boreholeName.toLowerCase().includes("-cpt")){
                    boreholeName = boreholeName.replace("-cpt", "");
                    boreholeName = boreholeName.replace("-CPT", "");
                }                

                if(boreholes[boreholeName]) {
                    boreholes[boreholeName].push(file);
                }
                else {
                    boreholes[boreholeName] = [file];
                }
            }

            Object.keys(boreholes).forEach(key => {
                const data = new FormData();
                let selectedFiles = boreholes[key];

                for (let i = 0; i < selectedFiles.length; i++) {
                    data.append(selectedFiles[i].name, selectedFiles[i])
                }
                let URL = "https://geotolk.azurewebsites.net/pred"
                //let URL = "http://localhost:5000/pred"
                axios.post(URL, data, config)
                    .then(res => {
                        this.handleLoadingChange(true)
                        let newBorhole = res.data;
                        let staticData = newBorhole.STATIC_DATA ? JSON.parse(newBorhole.STATIC_DATA) : null;
                        let maxPressure = this.findMaxPressure(staticData);

                        newBorhole.TLK = newBorhole.TLK ? JSON.parse(newBorhole.TLK) : null;
                        newBorhole.CPT = newBorhole.CPT ? JSON.parse(newBorhole.CPT) : null;

                        newBorhole.MAX_PRESSURE = maxPressure;
                        newBorhole.STATIC_DATA = staticData;

                        newBorhole.TOT_METADATA = newBorhole.TOT_METADATA ? JSON.parse(newBorhole.TOT_METADATA.replace(/\bNaN\b/g, "null")) : null;
                        newBorhole.SND_METADATA = newBorhole.SND_METADATA ? JSON.parse(newBorhole.SND_METADATA.replace(/\bNaN\b/g, "null")) : null;

                        newBorhole.BORHOLE_NAME = key;
                        newBorhole.DYNAMIC_DATA = this.makeDynamicData(JSON.parse(JSON.stringify(newBorhole.TLK)), maxPressure);

                        let boreholeId = uuidv4();

                        this.handleNewResponse(boreholeId, newBorhole);
                 })
                 .catch((error) => {
                    let uploadFileErrors = this.state.uploadFileErrors;

                    for(let i = 0; i < selectedFiles.length; i++) {
                        let fileName = selectedFiles[i].name;
                        let errorText = "Predikering av fil feilet"
                        if(error.response) {
                            errorText = `${error.response.data} (HTTP kode ${error.response.status})`;
                        }
                        uploadFileErrors.push(`${fileName} : ${errorText}`)
                    }

                    this.setState({ uploadFileErrors: uploadFileErrors })
                 })
                 .finally(res => {
                    this.setState({
                        uploadStatus: this.state.uploadStatus + selectedFiles.length
                    })

                    if(this.state.uploadStatus>=this.state.uploadTotal){
                        this.handleLoadingChange(false);
                    }
                 })
            }) 
        }
    }

    layerDepthOnChange = (e, val) => {
        this.setState({minLayerDepth: val});
    }

    smoothingDepthOnChange = (e, val) => {
        this.setState({smoothingDepth: val});
    }

    writeWhereOnChange = (e) => {
        this.setState({writeWhere: e.target.value});
    }

    resetAdvancedToDefaultOnclick = (e) => {
        this.setState({
            minLayerDepth: this.advancedOptionsDefault.minLayerDepth,
            smoothingDepth: this.advancedOptionsDefault.smoothingDepth,
            writeWhere: this.advancedOptionsDefault.writeWhere
        });
        console.log("reset to default");
    }

    render() {
        let uploadPercent = 0;
        if(this.state.uploadTotal>0){
            uploadPercent = (this.state.uploadStatus/this.state.uploadTotal)*100
        }

        //Progress line does not work in IE
        const isIE = (/*@cc_on!@*/false || !!document.documentMode);

        return (
            <div className="upload-body">
                <div className="upload-banner">
                    <div className="upload-card">
                        <div className="upload-text">
                            <h3>Last opp filer</h3>
                            <h4 className="upload-info">Last opp sonderingsfiler for å generere tolkninger. Gyldige filtyper er .SND .TLK og .PRV</h4>
                        </div>
                        <div className="fancy-input">
                            <div className="upload-image">
                                <Upload size={110}/>
                            </div>
                            <p className="file-upload-text">{this.state.uploadText}</p>
                            <input className="hidden-input" type="file" name="file" onChange={this.onChangeHandler} multiple />
                        </div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                <Typography className="accordion-heading">Avanserte alternativer</Typography>
                                </AccordionSummary>
                                <AccordionDetails className="accordionDetails">
                                    <div className="accordion-open">
                                        <div className="minThickLayer">
                                        <Tooltip placement="right" title="Denne kontrollen styrer minimumstykkelsen på lagene. Alle lag som er mindre en denne grensen vil bli slått sammen med nærliggende lag" arrow>
                                        <HelpOutlineRoundedIcon className="helpIcon" style={{ color: "grey" }}/>
                                        </Tooltip>
                                        <Typography>Minimumtykkelse for lag (m)</Typography>
                                        <Slider className="slider-layerdepth"
                                            defaultValue={this.advancedOptionsDefault.minLayerDepth}
                                            step={0.5}
                                            value={this.state.minLayerDepth}
                                            aria-labelledby="discrete-slider"
                                            min={0.5}
                                            max={10}
                                            onChange={this.layerDepthOnChange}
                                            valueLabelDisplay="auto"
                                        /> 
                                        </div>
                                        
                                        <div className="smoothingdepth">
                                        <Tooltip placement="right" title="Denne kontrollen styrer hvor mange meter som prediksjonen glattes. Ved å velge en lav verdi vil man kunne få mange, små lag, men vil bedre kunne fange brå endringer i laggrenser. Ved å velge en høy verdi vil man færre og større lag, men vil i mindre grad kunne fange opp brå endringer i lagdeling" arrow>
                                        <HelpOutlineRoundedIcon className="helpIcon" style={{ color: "grey" }}/>
                                        </Tooltip>
                                        <Typography className="smoothing-depth-input">Glatting av prediksjoner (m)</Typography>
                                        <Slider className="slider-smoothingdepth"
                                            defaultValue={this.advancedOptionsDefault.smoothingDepth}
                                            step={0.5}
                                            value={this.state.smoothingDepth}
                                            aria-labelledby="discrete-slider"
                                            min={0.5}
                                            max={20}
                                            onChange={this.smoothingDepthOnChange}
                                            valueLabelDisplay="auto"
                                        />
                                        </div>

                                        <div className="radioset">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Skriving av kote til TLK-fil</FormLabel>
                                            <RadioGroup row aria-label="position" name ="position" defaultValue={this.props.writeWhere}>
                                                <FormControlLabel value="top" control={<Radio color="primary" onChange={this.props.onWriteWhereChange}/>} label="Start av lag"/>
                                                <FormControlLabel value="bottom" control={<Radio color="primary" onChange={this.props.onWriteWhereChange}/>} label="Slutt av lag"/>
                                                <Tooltip className="radioTooltip" placement="right" title="Denne kontrollen styrer om starten eller slutten av laget skal bli skrevet i TLK-filen ved eksport. Ved å velge 'start av lag' vil kotehøyden til øvre del av laget bli skrevet til TLK-filen" arrow>
                                        <HelpOutlineRoundedIcon className="helpIcon" style={{ color: "grey" }}/>
                                        </Tooltip>
                                            </RadioGroup>
                                        </FormControl>
                                        </div>

                                        <div className="convertLayers">
                                        <Tooltip placement="right" title="Ved å sjekke av denne vil lagklassifikasjonene Udrenert, Drenert og Harde masser bli konvertert til Leire, Sand og Morene ved eksport til CSV eller TLK-filer" arrow>
                                        <HelpOutlineRoundedIcon className="helpIcon" style={{ color: "grey" }}/>
                                        </Tooltip>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={this.props.convertLayersStandard} onChange={this.props.onConvertLayersChange} name="convertLayers" />}
                                            label="Konverter ved eksport"
                                        />
                                        </div>
                                        <div className="setDefaultContainer">
                                        <Button variant="contained" onClick={this.resetAdvancedToDefaultOnclick} style={{ backgroundColor: "#2b5279", color: "white" }}> 
                                            Tilbakestill
                                        </Button>
                                        </div>


                                    </div>
                                </AccordionDetails>

                            </Accordion>
                        <p className="status-text">{this.state.uploadStatus}/{this.state.uploadTotal} tolkninger generert</p>
                        { isIE ? <div/> : <Line percent={uploadPercent} strokeWidth="1" strokeColor="#2c6384"/> }
                        <div align="center">
                            <button disabled={this.props.loading} className="upload-button" type="button" onClick={this.onClickHandler}>
                                { this.props.loading ? <ClipLoader size={30} color={"#2b5279"} loading={this.props.loading} /> : "Generer tolkninger" }
                            </button> 
                        </div>
                    </div>
                    {
                        this.state.uploadFileErrors.length > 0 ?
                            <div className="error-card">
                                <div className="upload-text">
                                    <h4>Feilmeldinger:</h4>
                                </div>
                                <ul className="error-list">
                                    {
                                        this.state.uploadFileErrors.map(error => (
                                            <li className="file-upload-error-text">{error}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        : null
                    }
                </div>
            </div>
        );
    }
}