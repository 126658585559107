
import React, { Component } from 'react'
/* import Leaflet from 'leaflet'; */
import L from 'leaflet';
import {CordConverter} from './CordConverter.js'
import { Map, TileLayer, Marker, LayersControl, WMSTileLayer, Tooltip} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import './Maps.css'
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import MuiTooltip from '@material-ui/core/Tooltip';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
  } from "@material-ui/core/styles";

const toolTheme = createMuiTheme();
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
        color: "#2A2A2A",
        backgroundColor: "white"
      }
    }
  }
});


const markerTotPrvCpt = {
    inactive: new L.Icon({
        iconUrl: require("../tot_prv_cpt-grey.svg"),
        iconSize: [75,75],
        popupAnchor: [0,-20],
        iconAnchor: [37, 43]
    }),
    active: new L.Icon({
        iconUrl: require("../tot_prv_cpt.svg"),
        iconSize: [75,75],
        popupAnchor: [0,-20],
        iconAnchor: [37, 43]
    }),
};

const markerTotPrv = {
    inactive: new L.Icon({
        iconUrl: require("../tot_prv-grey.svg"),
        iconSize: [46,46],
        popupAnchor: [0,-50],
        iconAnchor: [23, 46]
    }),
    active: new L.Icon({
        iconUrl: require("../tot_prv.svg"),
        iconSize: [46,46],
        popupAnchor: [0,-50],
        iconAnchor: [23, 46]
    }),
};

const markerTotCpt = {
    inactive: new L.Icon({
        iconUrl: require("../tot_cpt-grey.svg"),
        iconSize: [46,46],
        popupAnchor: [0,-50],
        iconAnchor: [23, 46]
    }),
    active: new L.Icon({
        iconUrl: require("../tot_cpt.svg"),
        iconSize: [46,46],
        popupAnchor: [0,-50],
        iconAnchor: [23, 46]
    }),
};

const markerTot = {
    inactive: new L.Icon({
        iconUrl: require("../tot-grey.svg"),
        iconSize: [46,46],
        popupAnchor: [0,-50],
        iconAnchor: [23, 46]
    }),
    active: new L.Icon({
        iconUrl: require("../tot_active.svg"),
        iconSize: [46,46],
        popupAnchor: [0,-50],
        iconAnchor: [23, 46]
    }),
};

export default class MapDisplay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            convertedCoordinates: [],
            bounds: null
        }
        this.cordZones = ["UTM32", "UTM33", "UTM35", 'NTM5', 'NTM6', 'NTM7', 'NTM8', 'NTM9', 'NTM10', 'NTM11', 'NTM12', 'NTM13', 'NTM14', 'NTM15', 'NTM16', 'NTM17', 'NTM18', 'NTM19', 'NTM20', 'NTM21', 'NTM22', 'NTM23', 'NTM24', 'NTM25', 'NTM26', 'NTM27', 'NTM28', 'NTM29', 'NTM30']
        this.handleCheck = this.props.handleCheck.bind(this);
    }

    handleZoneChange = (event) => {
        let newCoordinateSystem = this.props.currentCoordinateSystem
        newCoordinateSystem.zone = event.target.value.slice(0,3)
        newCoordinateSystem.number = parseInt(event.target.value.slice(3,5))
        this.props.onCoordinateSystemChange(newCoordinateSystem)
    };

    handleHemisphereChange = (event) => {
        let newCoordinateSystem = this.props.currentCoordinateSystem
        newCoordinateSystem.hemisphere = event.target.value 
        this.props.onCoordinateSystemChange(newCoordinateSystem)
    };

    getMarker = (id) => {
        let markerType;
        if (this.props.boreholeList[id].CPT !== null){
            if ("PRV" in this.props.boreholeList[id]){
                markerType = markerTotPrvCpt;
            }
            else{
                markerType = markerTotCpt;
            }
        }
        else{
            if ("PRV" in this.props.boreholeList[id]){
                markerType = markerTotPrv;
            }
            else{
                markerType = markerTot;
            }
        }
        let marker = markerType.inactive;

   if (this.props.checkedFiles.includes(id)){
            let marker = markerType.active;
            return marker
        }
       return marker;
    }

    markerOnClick = (e, id) => {
        this.handleCheck(e, id);
    }

    markerDblclick = (id) => {
        this.props.updateDetailedView(id);
        let element = document.getElementById("detailedView");
        if (element != null){
            element.scrollIntoView({behavior: "smooth"});
        }
        console.log("Doubleclicked");
    }

    convertCoordinates= (file) => {
        let x = file.SND_METADATA.x;
        let y = file.SND_METADATA.y;
        //convert to active coordinate system.
        //quick fix to convert to umt32N
        let coord = {
            easting: y,
            northing: x,
            zone: this.props.currentCoordinateSystem.zone,
            number: this.props.currentCoordinateSystem.number,
            hemisphere: this.props.currentCoordinateSystem.hemisphere
        }
        coord = CordConverter(coord);
        coord.index = file.INDEX;
        //this.handleAddCoordinate(coord);
        return coord
    }

    getMapBounds = () => {
        let bounds;
        let convertedCoordinates = Object.values(this.props.boreholeList).map(file => [
            this.convertCoordinates(file)
        ])
        if (convertedCoordinates[0] !== undefined){
            bounds = L.latLngBounds(convertedCoordinates)
        } else {
            bounds = L.latLngBounds([59.889802, 10.520750], [59.891802, 10.522750]); //startposisjon i sandvika
        }
        return bounds;
    }

    render() {
        let wms_overlay = [
            {
                "name": "Høydekurver",
                "url": 'https://openwms.statkart.no/skwms1/wms.topo4?',
                "layers": ["Hoydekurver", "Hoydepunkt"],
                "attribution": "Statkart"
            },
            {
                "name": "Kvikkleiresoner",
                "url": 'https://gis3.nve.no/map/services/SkredKvikkleire2/MapServer/WmsServer?',
                "layers": ["KvikkleireFaregrad"],
                "attribution": "NVE"
            },
            {
                "name": "Løsmasser",
                "url": "http://geo.ngu.no/mapserver/LosmasserWMS",
                "layers": ["Losmasse_flate_label"],
                "attribution": "NGU"
            },
            {
                "name": "Nadag grunnundersøkelser",
                "url": "http://geo.ngu.no/geoserver/nadag/wms",
                "layers": ["GBU_borefirma","GB_filter_kvikk"],
                "attribution": "NGU"
            },
            {
                "name": "Statens vegvesen grunnundersøkelser",
                "url": "https://www.vegvesen.no/kart/ogc/rapportweb_1_0/ows",
                "layers": ["Rapportwebgeoteknikk"],
                "attribution": "Statens vegvesen"
            },
            {
                "name": "Marin grense",
                "url": "http://geo.ngu.no/mapserver/MarinGrenseWMS3",
                "layers": ["MarinGrenseWMS3"],
                "attribution": "NGU"
            },
            {
                "name": "Bratthet",
                "url": 'https://gis3.nve.no/map/services/Bratthet/MapServer/WmsServer?',
                "layers": ["Bratthet_jordskred"],
                "attribution": "NVE"
            },
            {
                "name": "Berggrunn",
                "url": "http://geo.ngu.no/mapserver/BerggrunnWMS2",
                "layers": [""],
                "attribution": "NGU"
            },
            {
                "name": "Ortofoto",
                "url": "https://wms.geonorge.no/skwms1/wms.nib?",
                "layers": ["ortofoto"],
                "attribution": "GeoNorge",
                "opacity": 0.5
            }
        ]
        let bounds = this.getMapBounds();
        return (
            <div id="map" className="maps">
                <span>
                <h1 className="maps-h1">
                    Kartvisning
                    <MuiThemeProvider theme={theme}>
                    <MuiTooltip placement="top-start" title="Kartforklaring" arrow>
                        <HelpOutlineRoundedIcon className="helpIcon helpIcon-map" style={{ color: "#2b5279" }}/>
                    </MuiTooltip>
                    </MuiThemeProvider>
                </h1>
                </span>
                <div className="maps-header">
                    
                    <span className="map-zones">
                        <h3 className="maps-h3">Koordinatsystem</h3>
                            <span className="zone-input">
                            
                                <select id="zone" value={`${this.props.currentCoordinateSystem.zone}${this.props.currentCoordinateSystem.number}`} onChange={(e)=>{this.handleZoneChange(e)}}>
                                    {
                                        this.cordZones.map((zone, y) => 
                                            <option value={zone} key={y}>
                                                {zone}
                                            </option> 
                                            )
                                    }
                                </select>
                                <ul>
                                    <li key="north-option">
                                        <input 
                                            type="radio" 
                                            name="hemisphere" 
                                            id="n-option" 
                                            value="N" 
                                            onChange={(e)=>{this.handleHemisphereChange(e)}} 
                                            checked={this.props.currentCoordinateSystem.hemisphere === "N"}/>
                                        <label className="hemisphere-label" htmlFor="n-option">North</label>
                                        <span className="check"></span>
                                    </li>
                                    <li key="south-option">
                                        <input  
                                            type="radio" 
                                            name="hemisphere" 
                                            id="s-option" 
                                            value="S" 
                                            onChange={(e)=>{this.handleHemisphereChange(e)}}
                                            checked={this.props.currentCoordinateSystem.hemisphere === "S"} />
                                        <label className="hemisphere-label" htmlFor="s-option">South</label>
                                        <span className="check"></span>
                                        </li>
                                </ul>
                            </span>
                    </span>                   
                </div>

                <div className="maps-left">
                </div>
                <Map className="markercluster-map" bounds={bounds} doubleClickZoom={false} style={{width: '100%',height: '900px'}}>
                    <LayersControl position="topright" expanded="true" align="left">
                        <LayersControl.BaseLayer name = "OpenStreetmap" checked='true' key="openstreetmap">
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                opacity="0.9">
                            </TileLayer>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Ortofoto" key="ortofoto">
                            <WMSTileLayer attribution="GeoNorge"
                            url="https://wms.geonorge.no/skwms1/wms.nib?"
                            layers="ortofoto"
                            format="image/png"
                            version="1.3.1"
                            transparent="true">

                            </WMSTileLayer>
                        </LayersControl.BaseLayer>
                        {wms_overlay.map((wms, index) => {
                            return(
                                <LayersControl.Overlay name={wms["name"]} key={index}>
                                    <WMSTileLayer attribution={wms["attribution"]}
                                    url={wms["url"]}
                                    layers={wms["layers"]}
                                    version="1.3.0"
                                    format="image/png"
                                    transparent="true"
                                    opacity={"opacity" in wms ? wms["opacity"]: 1}>
                                    </WMSTileLayer>
                                </LayersControl.Overlay>
                            )
                        })}
                    </LayersControl>
                    <MarkerClusterGroup disableClusteringAtZoom={17}>
                        {Object.entries(this.props.boreholeList).map(([key, file]) => {
                            let coord = this.convertCoordinates(file);
                            let coordtext = coord.lat + ", " + coord.lng;
                            let icon = this.getMarker(key);
                            return (
                                <Marker className="marker-popup"
                                    icon={icon}
                                    position={coord}
                                    title={coordtext}
                                    onclick={e => this.markerOnClick(e, key)}
                                    ondblclick={() => this.markerDblclick(key)}
                                    key={key}
                                >
                                    <Tooltip
                                        permanent="true"
                                        direction="top"
                                        offset={[0,-45]}
                                        key={"tooltip-" + key}
                                    >
                                        <p className="file-tooltip" id="fileTooltip">{file.BORHOLE_NAME}</p>
                                    </Tooltip>

                                </Marker>
                        )
                        })}
                    </MarkerClusterGroup>
                </Map>
            </div>
        )
    }
}