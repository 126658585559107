import React from 'react';
import './Graphs.css'
import * as am4core from "@amcharts/amcharts4/core";
import Radio from '@material-ui/core/Radio';
import { SortableHandle } from 'react-sortable-hoc';
import { InitializeLayerGraph } from './LayerGraph';

const Header = SortableHandle(({borehole, radioOnChange, radioChecked, radioOnClick}) => 
    <div className="analysis-graph-header">
        <h3 className="analysis-graph-header-text">{borehole}</h3>
        <Radio
                onChange={radioOnChange}
                checked={radioChecked}
                onClick={radioOnClick}
                color="primary"
                />
    </div>);

export default class AnalysisGraph extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            containerId: "analysisChart-" + this.props.analysisGraphId,
            prevMaxDepth: this.props.maxDepth,
        }
    }

    componentDidMount() {
        this.initChart();
    }

    componentWillUnmount() {
        if(this.chart) {
            this.chart.dispose();
        }
    }

    componentDidUpdate() {
        if(this.props.maxDepth !== this.state.prevMaxDepth) {
            this.chart.dispose();
            this.initChart();
            this.setState({ prevMaxDepth: this.props.maxDepth });
        }
        if(this.props.analysisGraphId === this.props.updateAnalysisId) {
            this.chart.invalidateData();
            this.pressureSeries.data = this.props.borehole.STATIC_DATA;
            this.props.resetUpdateAnalysisId();
        }
    }

    openDetailedView = () => {
        let index = this.props.analysisGraphId;
        this.props.updateDetailedView(index);
    }

    updateChartData = () => {
        this.sortData();
        this.chart.invalidateData();
        this.pressureSeries.data = this.props.borehole.STATIC_DATA;
        this.props.onDynamicDataChange(this.props.dynamicData, this.props.analysisGraphId); 
    }

    sortData = () => {
        this.chart.data.sort(function(a, b) {
            var aY = a.start;
            var bY = b.start;

            if (aY < bY) {
            return -1;
            }
            else if (aY === bY) {
            return 0;
            }
            else {
            return 1;
            }
        })

        for(var i = 0; i < this.chart.data.length - 1; i++) {
            this.chart.data[i].stop = this.chart.data[i + 1].start;
        }
    }

    absoluteHeight = () => {
        let meter_per_px = 620 / this.props.maxDepth;
        return meter_per_px * (this.props.maxKote - this.props.dynamicData[0].kote);
    }

    radioClick = (e) => {
        let element = document.getElementById("detailedView");
        if (element != null){
            element.scrollIntoView({behavior: "smooth"});
        }
    }

    initChart() {
        let dataMaxDepth = this.props.dynamicData[0].max_depth;
        let analysisMaxDepth = this.props.maxDepth;

        let layerGraphComponents = InitializeLayerGraph(
            this.props.borehole.STATIC_DATA, 
            this.props.dynamicData, 
            this.state.containerId, 
            this.updateChartData);

        let chart = layerGraphComponents.chart;
        let pressureSeries = layerGraphComponents.pressureSeries;
        let xAxis = layerGraphComponents.xAxis;

        xAxis.title.dy = 8;
        chart.svgContainer.htmlElement.style.width = "220px";

        this.chart = chart;
        this.pressureSeries = pressureSeries;

        pressureSeries.tooltip.getFillFromObject = false;
        pressureSeries.tooltip.autoTextColor = false;
        pressureSeries.tooltip.fontSize = 12;
        pressureSeries.tooltip.label.fill = am4core.color("black");

        pressureSeries.tooltip.background.filters.clear();
        pressureSeries.tooltip.background.fillOpacity = 1;
        pressureSeries.tooltip.background.fill = am4core.color("white");
        pressureSeries.tooltip.background.stroke = am4core.color("black");

        pressureSeries.tooltip.pointerOrientation = "left";

        pressureSeries.adapter.add("tooltipHTML", (text, target) => {
            let fillColor = "";
            let type = "";
            let depth = "";
            let pressure = "";

            let dataContext = target.tooltipDataItem.dataContext
            
            if(dataContext) {
                depth = dataContext["Dybde"].toFixed(2);
                pressure = (dataContext["Trykk"] / 1000).toFixed(2);
    
                for(var i = 0; i < chart.data.length; i++) {
                    if(chart.data[i].start < depth && chart.data[i].stop > depth) {
                        fillColor = chart.data[i].fillColor;
                        type = chart.data[i].type;
                        break;
                    }
                }
            }

            let tooltipHtml = 
                `<p><strong>Dybde: ${depth} m</strong></p>
                <p><strong>Matekraft: ${pressure} kN</strong></p>
                <p><div class="labelBox" style='background: ${fillColor}'></div><strong>${type}</strong></p>`

            return tooltipHtml;
          });

        chart.events.on("datavalidated", function(ev) {
            let chart = ev.target;
            let calculatedHeight = 620 * dataMaxDepth / analysisMaxDepth;

            chart.svgContainer.htmlElement.style.height = calculatedHeight + "px";
        })
    }

    render() {
        let height = 0;

        if(this.props.showingAbsoluteHeight) {
            height = this.absoluteHeight();
        }

        return (
            <div className="analysis-graph-container">
                <Header
                    borehole={this.props.borehole.BORHOLE_NAME}
                    radioOnChange={this.openDetailedView}
                    radioChecked={this.props.detailedViewId === this.props.analysisGraphId}
                    radioOnClick={this.radioClick}/>
                <div className="absolute-depth" style={{ height: height }}></div>
                {
                    this.props.showingAbsoluteHeight ?
                    <div className="kote">Kote: {this.props.dynamicData[0].kote.toFixed(2)} moh</div> :
                    <div/>
                }

                <div className="dynamicGraph" id={this.state.containerId}>
                </div>
            </div>
        )
      }

  }