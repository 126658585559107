import React from 'react';
import './Graphs.css'

import {
    BarChart,
    Bar,
    Line,
    LineChart,
    XAxis,
    YAxis,
    ZAxis,
    Tooltip,
    CartesianGrid,
    ScatterChart,
    Scatter, ReferenceLine
  } from "recharts";

export default class TotalSoundingGraphs extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            prevDetailedViewId: this.props.detailedViewId,
            rotationList: []
        }
    }

    componentDidMount() {
        this.makeRotationList();
    }

    componentDidUpdate() {
        if(this.state.prevDetailedViewId !== this.props.detailedViewId) {
            this.makeRotationList();
            this.setState({ prevDetailedViewId: this.props.detailedViewId });
        } 
    }

    makeRotationList = () => {
        let staticData = this.props.staticData;
        let rotationList = [];

        //Need two seperate datapoints going opposite ways to create cross-graph
        let rightAlignedPoints = [];
        let leftAlignedPoints = [];

        let alreadyRotating = false;

        for(var i = 0; i < staticData.length; i++) {
            let dataPoint = staticData[i];

            let rotating = dataPoint["Økt rotasjon"] === 1;

            let rotationStopped = !rotating && alreadyRotating;
            let rotationStarted = rotating && !alreadyRotating;
            let stillRotatingOnLastDataPoint = rotating && i === staticData.length - 1;

            if (rotationStopped || rotationStarted || stillRotatingOnLastDataPoint) {
                let depth = dataPoint["Dybde"];

                rightAlignedPoints.push(
                    {Dybde: depth, x: 0},
                    {Dybde: depth, x: 1}
                )
                leftAlignedPoints.push(
                    {Dybde: depth, x: 1},
                    {Dybde: depth, x: 0}
                )

                if (!rotationStarted) {
                    rotationList.push(rightAlignedPoints, leftAlignedPoints);
                    rightAlignedPoints = [];
                    leftAlignedPoints = [];
                }

                alreadyRotating = !alreadyRotating;
            }
        }

        this.setState({ rotationList: rotationList });
    }

    render() {
        let staticData = this.props.staticData;

        let dataMin = staticData[0]["Dybde"];
        let dataMax = staticData[staticData.length - 1]["Dybde"];

        let large_width = 180;
        let small_width = 50;

        let bottomMargin = 50;
        let leftMargin = 5;
        let tickCount = 10;

        const BasicTooltip = ({ active, payload, label }) => {
            if(active) {
                return <div className="tooltip">
                        <p>{`${label} m:`}</p>
                        <ul className="tooltip-list">
                            {payload.map((entry, index) => {
                                return <li 
                                        className="tooltip-list-item" 
                                        key={index} 
                                        style={{ color: entry["color"] }}>
                                    {`${entry["dataKey"]}: ${entry["value"]}`}
                                    </li>
                            })}
                        </ul>
                    </div>
            }
            return null
        }

        const RotationTooltip = ({active, payload, label}) => {
            if(active) {
                return <div className="tooltip">
                    {payload.map((entry, index) => {
                        if(entry["dataKey"] === "Dybde") {
                            return <div><p>{`${entry["value"]} m`}</p><p>Økt rotasjon</p></div>
                        }
                        return <div/>
                      })}
                    </div>                
            }

            return null;
        }

        return (
        <div className="staticGraphs">
            <div className="content">
                <LineChart 
                    layout="vertical"
                    height={700} width={large_width} 
                    data={staticData}
                    margin={{ bottom: 10, left: 10 }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis 
                    type="number" 
                    label={{value: "Bortid [s/m]", fontWeight: "bold", dy: 10, fill: "#39A1E6" }} 
                    xAxisId={0} 
                    height={40}/>
                <XAxis 
                    type="number" 
                    label={{value: "Spyletrykk [MPa]", fontWeight: "bold", dy: 15, fill: "#B83732" }} 
                    reversed={true} 
                    xAxisId={1}/>
                <YAxis 
                    dataKey="Dybde" 
                    type="number"
                    //label={{ value: 'Dybde [m]', angle: -90, fontWeight: "bold", position: 'insideLeft', dx: 10 }}
                    domain={['dataMin', 'dataMax']}
                    tickCount={tickCount}
                    yAxisId={0}/>
                <YAxis 
                    dataKey="Dybde" 
                    type="number" 
                    orientation="right" 
                    tick={false}
                    width={5}
                    domain={['dataMin', 'dataMax']} 
                    yAxisId={1}/>
                <Tooltip content={<BasicTooltip/>}/>
                <Line dataKey="Bortid" stroke="#39A1E6" dot={false} yAxisId={0} xAxisId={0}/>
                <Line dataKey="Spyletrykk" stroke="#B83732" dot={false} yAxisId={1} xAxisId={1}/>
                </LineChart>
            </div>
            <div className="content">
                <BarChart height={700} width={small_width}
                layout="vertical"
                data={staticData}
                margin={{ bottom: bottomMargin, left: leftMargin }}
                barCategoryGap="-1"
                isAnimationActive={false}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        type="number"
                        tick={false}
                        label={{ value: 'Spyling', angle: -65, fontWeight: "bold", fill: "#292624", position: "left", dx: 25 }}
                        domain={[0,1]} />
                    <YAxis
                        type="number"
                        dataKey="Dybde"
                        stroke="black"
                        tick={{ fontSize: 0 }}
                        tickCount={tickCount}
                        width={10}
                        domain={['dataMin', 'dataMax']}/>
                    <Tooltip content={<BasicTooltip/>}/>
                    <ReferenceLine x={1} stroke="black"/>
                    <Bar dataKey="Spyling" fill="#292624" opacity="0.7"/>
                </BarChart>
            </div>
            <div className="content">
                <BarChart height={700} width={small_width}
                layout="vertical"
                data={staticData}
                margin={{ bottom: bottomMargin, left: leftMargin }}
                barCategoryGap="-1"
                isAnimationActive={false}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        type="number"
                        tick={false}
                        label={{ value: 'Slagboring', angle: -65, fontWeight: "bold", fill: "#292624", position: "left", dx: 25 }}
                        domain={[0,1]} />
                    <YAxis
                        type="number"
                        dataKey="Dybde"
                        stroke="black"
                        tick={{ fontSize: 0 }}
                        tickCount={tickCount}
                        width={10}
                        domain={['dataMin', 'dataMax']}/>
                    <Tooltip content={<BasicTooltip/>}/>
                    <ReferenceLine x={1} stroke="black"/>
                    <Bar dataKey="Slagboring" fill="#292624" opacity="0.7"/>
                </BarChart>
            </div>
            <div className="content">
                <ScatterChart 
                width={small_width} 
                height={700} 
                margin={{bottom: bottomMargin, left: leftMargin }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                    type="number" 
                    dataKey={'x'}
                    tick={false}
                    label={{ value: 'Økt rotasjon', angle: -65, fontWeight: "bold", fill: "black", position: "left", dx: 25, dy: -8 }}
                    domain={[0,1]} />
                <YAxis 
                    type="number" 
                    dataKey='Dybde'
                    stroke="black"
                    reversed={true}
                    tick={{ fontSize: 0 }}
                    tickCount={tickCount}
                    width={10}
                    domain={[dataMin, dataMax]}/>
                <ZAxis range={[100]}/>
                <Tooltip content={<RotationTooltip/>}/>
                <ReferenceLine x={1} stroke="black"/>
                {
                    this.state.rotationList.map((dataPoint, index) => (
                        <Scatter name='Rotation' data={dataPoint} fillOpacity={0} fill='black' line shape="circle" key={index}/>          	
                    ))
                }
               </ScatterChart>
            </div>
        </div>
        )
    }
}