import React, { Component } from 'react'
import './Analysis.css';
import AnalysisGraph from '../Graphs/AnalysisGraph'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import {SortableContainer, SortableElement } from 'react-sortable-hoc';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import Tooltip from '@material-ui/core/Tooltip';

const SortableItem = SortableElement(({
        props, graphIndex
    }) => (
        <AnalysisGraph
            detailedViewId={props.detailedViewId}
            updateAnalysisId = {props.updateAnalysisId}
            resetUpdateAnalysisId = {props.resetUpdateAnalysisId}
            borehole = { props.boreholeList[graphIndex] }
            dynamicData = { props.boreholeList[graphIndex].DYNAMIC_DATA }
            maxDepth = {props.maxDepth}
            maxKote = {props.maxKote}
            showingAbsoluteHeight = {props.showingAbsoluteHeight}
            colorConfig={props.colorConfig}
            updateDetailedView={props.updateDetailedView}
            onDynamicDataChange={props.onDynamicDataChange}
            analysisGraphId={graphIndex}
            key = {graphIndex} />
));
  
const SortableList = SortableContainer(({
        props
    }) => (
    <div className="sortable-list-container">
        { props.analysisActiveIds ? props.analysisActiveIds.map((value, index) => (
            <SortableItem 
            key={`sortable-item-${value}`} 
            index={index} 
            props={props}
            graphIndex={value}/>
        ))  : <div/>}
    </div>
));

export default class Analysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingAbsoluteHeight: false
        }
    }

    onDynamicDataChange = (newDynamicData, id) => {
        let boreholeList = this.props.boreholeList;
        boreholeList[id].DYNAMIC_DATA = newDynamicData;
        this.props.onDynamicDataChange(boreholeList, -1, true);
    }

    resetUpdateAnalysisId = () => {
        this.props.setUpdateAnalysisId("");
    }

    toggleAbsoluteHeight = () => {
        let showAbsoluteHeight = !this.state.showingAbsoluteHeight;
        this.setState({ showingAbsoluteHeight: showAbsoluteHeight });
    }

    changeAnalysisActiveIdsOrder = ({oldIndex, newIndex}) => {
        this.props.changeAnalysisActiveIdsOrder(oldIndex, newIndex);
      };

    render() {
        let maxDepth = 0;
        let maxKote = 0;

        if(this.props.analysisActiveIds.length > 0) {
            this.props.analysisActiveIds.forEach(id => {
                let dataList = this.props.boreholeList[id].DYNAMIC_DATA[0];

                if(dataList.max_depth > maxDepth){
                    maxDepth = dataList.max_depth;
                }

                if(dataList.kote > maxKote) {
                    maxKote = dataList.kote;
                }
            })
        }

        let graphProperties = {
            ...this.props,
            maxDepth: maxDepth, 
            maxKote: maxKote,
            showingAbsoluteHeight: this.state.showingAbsoluteHeight,
            resetUpdateAnalysisId: this.resetUpdateAnalysisId,
            onDynamicDataChange: this.onDynamicDataChange
        };

        return (

        <div id="analysis" className="analysis">
            <h1 className="analysis-h1">
                Tolkningsplott
                <Tooltip placement="top-start" title="Her vises tolkningsplottene for sonderingene du har valgt i listen til venstre" arrow>
                <HelpOutlineRoundedIcon className="helpIcon helpIcon-plot" style={{ color: "#2b5279" }}/>
                </Tooltip>
            </h1>
            { this.props.analysisActiveIds.length > 1 ? 
                <div className="active-analysis-header">
                    <FormControlLabel 
                            control={<Switch 
                                color="primary" 
                                onChange={this.toggleAbsoluteHeight}
                                checked={this.state.showingAbsoluteHeight}
                                />}
                            label="Absolutt høyde"
                            labelPlacement="start"/>
                <p className="analysis-draggable-info">Du kan endre rekkefølgen på grafene ved å dra de sidelengs.</p>
                </div> : <div/>} 
             <div className="container-row">
                {
                    <SortableList 
                    axis="x" 
                    lockAxis="x"
                    helperClass="drag-analysis-graph"
                    useDragHandle={true}
                    onSortEnd={this.changeAnalysisActiveIdsOrder}
                    props= {graphProperties}/>
                }
            </div>
        </div>
    )}
}