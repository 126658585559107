
import UTMLatLng from 'utm-latlng';

function degree(rad){
    return (rad*180)/Math.PI
}

function convertNTM(easting, northing, number){
    northing += 23
    var semiMajorAxis = 6378137.000	
    var flattening = 1/298.257222101
    var latNaturalOrigin = 1.01229097
    var lonNaturalOrigin = 0.0959931089 + (0.0174532925*(number-5))
    var scaleFactor = 1
    var falseEasting = 100000
    var falseNorthing = 1000000

    var e = Math.sqrt(2*flattening-Math.pow(flattening, 2))
    var n = flattening / (2-flattening)
    var B = (semiMajorAxis/(1+n))*(1 + Math.pow(n,2)/4 + Math.pow(n,4)/64)
    var h1 = n/2 - (2/3)*Math.pow(n, 2) + (5/16)*Math.pow(n, 3) + (41/180)*Math.pow(n, 4)
    var h2 = (13/48)*Math.pow(n, 2) - (3/5)*Math.pow(n, 3) + (557/1440)*Math.pow(n, 4)
    var h3 = (61/240)*Math.pow(n, 3) - (103/140)*Math.pow(n, 4)
    var h4 = (49561/161280)*Math.pow(n, 4)

    var Q_o = Math.asinh(Math.tan(latNaturalOrigin)) - (e*Math.atanh(e*Math.sin(latNaturalOrigin)))
    var beta_o = Math.atan(Math.sinh(Q_o))
    var xi_O0 = Math.asin(Math.sin(beta_o))
    var xi_O1 = h1*Math.sin(2*xi_O0)
    var xi_O2 = h2*Math.sin(4*xi_O0) 
    var xi_O3 = h3*Math.sin(6*xi_O0) 
    var xi_O4 = h4*Math.sin(8*xi_O0) 
    var xi_O = xi_O0+ xi_O1+ xi_O2+ xi_O3+ xi_O4
    var Mo = B*xi_O

    var h1_ = n/2 - (2/3)*Math.pow(n, 2) + (37/96)*Math.pow(n, 3) - (1/360)*Math.pow(n, 4)
    var h2_ = (1/48)*Math.pow(n, 2) + (1/15)*Math.pow(n, 3) - (437/1440)*Math.pow(n, 4)
    var h3_ = (17/480)*Math.pow(n, 3) - (37/840)*Math.pow(n, 4)
    var h4_ = (4397/161280)*Math.pow(n, 4)

    var eta_ = (easting -  falseEasting) / (B*scaleFactor)				
    var xi_ = ((northing - falseNorthing) + scaleFactor*Mo) / (B*scaleFactor)
    var xi_1_ = h1_*Math.sin(2*xi_)*Math.cosh(2*eta_)                      
    var xi_2_ = h2_*Math.sin(4*xi_)*Math.cosh(4*eta_)                      
    var xi_3_ = h3_*Math.sin(6*xi_)*Math.cosh(6*eta_)                      
    var xi_4_ = h4_*Math.sin(8*xi_)*Math.cosh(8*eta_)    
    var xi_0_ = xi_ - (xi_1_ + xi_2_ + xi_3_ + xi_4_)                  
    var eta_1_ = h1_*Math.cos(2*xi_)*Math.sinh(2*eta_)
    var eta_2_ = h2_*Math.cos(4*xi_)*Math.sinh(4*eta_)
    var eta_3_ = h3_*Math.cos(6*xi_)*Math.sinh(6*eta_)
    var eta_4_ = h4_*Math.cos(8*xi_)*Math.sinh(8*eta_)
    var eta_0_ = eta_ - (eta_1_ + eta_2_ + eta_3_ + eta_4_)
						
    var beta_ = Math.asin(Math.sin(xi_0_) / Math.cosh(eta_0_))
    var Q_ = Math.asinh(Math.tan(beta_))
    var Q__ = Q_ + (e*Math.atanh(e*Math.tanh(Q_)))
    var Lat = Math.atan(Math.sinh(Q__))
    var Lon = lonNaturalOrigin + Math.asin(Math.tanh(eta_0_) / Math.cos(beta_))
    return {lat: degree(Lat), lng: degree(Lon)}
}

export function CordConverter(cord){
    if (cord.zone === "UTM"){
        var coordConverter =  new UTMLatLng('WGS 84');  
        return coordConverter.convertUtmToLatLng(cord.easting, cord.northing, cord.number, cord.hemisphere)
    }
    else if (cord.zone === "NTM"){
        return convertNTM(cord.easting, cord.northing, cord.number)
    }
}