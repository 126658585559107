import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export function InitializeLayerGraph(
    staticData, dynamicData, containerId, updateChartData) {
    var chart = am4core.create(containerId, am4charts.XYChart);
        chart.data = dynamicData;
        chart.numberFormatter.numberFormat = "###.##";

        var xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.strictMinMax = true;
        xAxis.width = 130;
        xAxis.cursorTooltipEnabled = false;
        xAxis.renderer.ticks.template.disabled = true;
        xAxis.renderer.labels.template.disabled = true;
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.baseGrid.disabled = true;
        xAxis.title.text = "Matekraft [kN]";
        xAxis.fontSize = 12;
        xAxis.fontWeight = "bold";
        xAxis.baseValue = -1000;

        var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.renderer.inversed = true;
        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.ticks.template.disabled = false;
        yAxis.renderer.line.strokeOpacity = 1;
        yAxis.renderer.line.strokeWidth = 2;
        yAxis.strictMinMax = true;
        yAxis.fontSize = 12;
        yAxis.maxPrecision = 2;
        yAxis.cursorTooltipEnabled = false;
        yAxis.renderer.baseGrid.disabled = true;

        // Create series
        var layerSeries = chart.series.push(new am4charts.StepLineSeries());
        layerSeries.dataFields.valueY = "start";
        layerSeries.dataFields.valueX = "pressure";
        layerSeries.name ="Layers";
        layerSeries.strokeWidth = 1;
        layerSeries.fillOpacity = 0.9;
        layerSeries.strokeOpacity = 0.9;
        layerSeries.propertyFields.fill = "fillColor";
        layerSeries.propertyFields.stroke = "fillColor";
        layerSeries.baseAxis = yAxis;
        layerSeries.reverseOrder = true;

        var pressureSeries = chart.series.push(new am4charts.LineSeries());
        pressureSeries.data = staticData;
        pressureSeries.dataFields.valueY = "Dybde";
        pressureSeries.dataFields.valueX = "Trykk";
        pressureSeries.name = "Trykk";
        pressureSeries.strokeWidth = 1;
        pressureSeries.baseAxis = yAxis;
        pressureSeries.stroke = "black";

        var layerBullet = layerSeries.bullets.push(new am4charts.CircleBullet());
        layerBullet.circle.fillOpacity = 0;
        layerBullet.circle.strokeOpacity = 0;
        layerBullet.propertyFields.draggable = "draggable";
        layerBullet.propertyFields.disabled = "bulletDisabled";
        layerBullet.cursorOverStyle = am4core.MouseCursorStyle.grab;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "none";
        chart.cursor.snapToSeries = pressureSeries;

        var rectangle = layerBullet.createChild(am4core.Rectangle);
        rectangle.width = am4core.percent(100);
        rectangle.height = 16;
        rectangle.fill = "grey";
        rectangle.stroke = "grey";
        rectangle.fillOpacity = 0;
        rectangle.strokeOpacity = 0;
        rectangle.dy = 6;
        rectangle.rotation = "180";

        var dottedLine = layerBullet.createChild(am4core.Line);
        dottedLine.rotation = "180";
        dottedLine.fill = "grey";
        dottedLine.stroke = "grey";
        dottedLine.strokeWidth = 2;
        dottedLine.width = am4core.percent(100);
        dottedLine.strokeDasharray = "3,3"

        layerBullet.events.on("dragged", function(event) {
            var layerBullet = event.target;
            layerBullet.cursorOverStyle = am4core.MouseCursorStyle.verticalResize
            
            var x = layerBullet.pixelX; 
            x = xAxis.getX(layerBullet.dataItem, "valueX");

            var newYvalue = yAxis.yToValue(layerBullet.pixelY);
            var maxYValue = chart.data[chart.data.length - 1].start;
            var minYValue = chart.data[0].start;

            var point = layerBullet.dataItem.dataContext;
            
            try {
                if(newYvalue > maxYValue - 0.1) {
                    let maxPixelY = yAxis.valueToPosition(maxYValue - 0.1);
                    layerBullet.moveTo({ x: x, y: maxPixelY }, undefined, undefined, true);
                    layerBullet.dataItem.valueY = maxYValue - 0.1;
                    point.start = layerBullet.dataItem.valueY;
                    chart.data[chart.data.indexOf(point) - 1].stop = layerBullet.dataItem.valueY;  
                    layerBullet.dispatchImmediately("dragstop");
                } else if(newYvalue < minYValue + 0.1) {
                    let minPixelY = yAxis.valueToPosition(minYValue + 0.1);
                    layerBullet.moveTo({ x: x, y: minPixelY }, undefined, undefined, true);
                    layerBullet.dataItem.valueY = minYValue + 0.1;
                    point.start = layerBullet.dataItem.valueY;
                    chart.data[chart.data.indexOf(point) - 1].stop = layerBullet.dataItem.valueY;  
                    layerBullet.dispatchImmediately("dragstop");                
                }
                else {
                    layerBullet.moveTo({ x: x, y: layerBullet.pixelY }, undefined, undefined, true);
                    layerBullet.dataItem.valueY = newYvalue;
                    point.start = layerBullet.dataItem.valueY;
                    chart.data[chart.data.indexOf(point) - 1].stop = layerBullet.dataItem.valueY;   
                }
            } catch (error) {
                console.log(error);
            }

        })

        layerBullet.events.on("dragstop", function(event) {
            layerBullet.cursorOverStyle = am4core.MouseCursorStyle.grab;
            updateChartData();
        })

        return {
            "chart": chart, 
            "pressureSeries": pressureSeries, 
            "layerBullet": layerBullet,
            "xAxis": xAxis,
            "yAxis": yAxis
        };
}