import React from 'react';
/* import analyticsIcon from './analyticsIcon.png' */
// import geotolkLogo from './geotolk_logo.svg'
import geotolkLogo from './geotolk-12.svg'
import './Introduction.css'

export default class Introduction extends React.Component {
    render(){
        return(
            <div className="introduction">
                <div className="introduction-left">
                    <img alt="Geotolk" src={geotolkLogo} width="400px" align="center" id="logo"/>
                    <h3 className="intro-h3">Grovtolkning av laginndeling for totalsonderinger</h3>
                    <p className="intro-p">
                        Denne tjenesten benytter en maskinlæringsmodell som er trent opp på 58 000 totalsonderinger utført av Norconsult. Nærliggende manuelle tolkninger, jordprøver og CPT-undersøkelser er brukt for å lære opp modellen. Denne tjenesten er ment som et verktøy for å grovtolke totalsonderinger, for videre tolkning av geoteknikere. Resultater fra tjenesten må ikke brukes i oppdrag uten et kyndig blikk gjennom en geoteknikers lupe.
                    </p>
                </div>
                {/* <div className="introduction-right">
                    <img alt="" src={totalSondering} width="270px" />
                </div> */}
            </div>
        )
    }
}