import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import UploadFiles from './UploadFiles/UploadFiles';
import Introduction from './Introduction/Introduction';
import DetailedView from './DetailedView/DetailedView';
import FilesList from './DetailedView/FilesList';
import { arrayMove } from 'react-sortable-hoc';
import { set, get } from 'idb-keyval'; 

import MapDisplay from './Maps/Maps';
import Analysis from './Analysis/Analysis';
import geotolkLogo from './Introduction/geotolk-blue.svg';
import './App.css';

class App extends React.Component{
    constructor(props) {
        super(props);

        this.defaultParams = {
            writeWhere: "top",
            convertLayersStandard: false
        }

        this.state = {
            boreholeList: {},
            toggleOpen: true,
            analysisActiveIds: [],
            checkedFiles: [],
            allChecked: false,
            detailedViewId: "",
            currentCoordinateSystem: {zone: "UTM", number: 32, hemisphere:'N'},
            coordinates: [],
            loading: false,
            updateAnalysisId: "",
            updateDetailedView: false,
            writeWhere: this.defaultParams.writeWhere,
            convertLayersStandard: this.defaultParams.convertLayersStandard,
            colorConfig: {
                "Kvikkleire": "#EB4036",
                "Leire": "#EBE25B",
                "Tørrskorpe leire": "#87241F",
                "Silt": "#FFCB57",
                "Sand": "#4E9937",
                "Friksjonsjord": "#5BA1C2",
                "Stein": "#787169",
                "Morene": "#787169",
                "Harde masser": "#787169",
                "Berg": "#292624",
                "Drenert": "#6CCF50",
                "Udrenert": "#EBE25B"
            }
        }
    }

    saveState = () => {
        set("state", JSON.stringify(this.state));
    } 

    loadState = () => {
        get("state").then(
            state => {
                state = state ? JSON.parse(state) : null
                
                if(state && Object.entries(state).length !== 0) {
                    state.loading = false;
                    this.setState(state);
                }
            });
    }

    componentDidMount = () => {
        document.title = "Geotolk";
        this.loadState()
    }

    componentDidUpdate = () => {
        this.saveState();
    }

    handleStagedForAnalysisChange = (newStagedForAnalysis) => {
        this.setState({ stagedForAnalysis: newStagedForAnalysis})
    }

    handleUploadFile = (boreholeList) => {
        this.setState({ boreholeList: boreholeList })
    }

    handleBorholeChange = (boreholeList, updataAnalysisId, updateDetailedView) => {
        this.setState({ 
            boreholeList: boreholeList, 
            updateAnalysisId: updataAnalysisId, 
            updateDetailedView: updateDetailedView })
    }

    handleCoordinateSystemChange = (newCoordinateSystem) => {
        this.setState({ currentCoordinateSystem: newCoordinateSystem });
    }

    handleCoordinatesChange = (newCoordinates) =>{
        this.setState({ coordinates: newCoordinates });
    }

    handleLoadingChange = (newLoading) => {
        this.setState({loading: newLoading});
    }

    handleAnalysisChange = (newActiveBorholes) => {
        this.setState({ analysisActiveIds: newActiveBorholes });
    }

    handleDetailedViewChange = (id) => {
        this.setState({ detailedViewId: id });
    }

    handleSetSidebarOpen = (value) => {
        this.setState({sidebarOpen: value});
    }

    handleListToggle = (value) => {
        this.setState({toggleOpen: value});
    }

    handleUpdateAnalysisIdChange = (id) => {
        this.setState({ updateAnalysisId: id });
    }

    handleUpdateDetailedViewChange = (bool) => {
        this.setState({ updateDetailedView: bool });
    }

    changeAnalysisActiveIdsOrder = (oldIndex, newIndex) => {
        this.setState({
            analysisActiveIds: arrayMove(this.state.analysisActiveIds, oldIndex, newIndex),
        });       
    }

    handleCheck = (e, fileId) => {
        this.setState(state => ({
            checkedFiles: state.checkedFiles.includes(fileId)
                ? state.checkedFiles.filter(c => c !== fileId)
                : [...state.checkedFiles, fileId]
            }));
    }

    handleCheckAll = (e) => {
        let allChecked = this.state.allChecked;
        if(allChecked) {
            this.setState({checkedFiles: [], allChecked: false});
        } else {
            this.setState({checkedFiles: Object.keys(this.state.boreholeList), allChecked: true});
       }
    }

    handleDelete = () => {
        let checkedFiles = this.state.checkedFiles;
        let boreholeList = this.state.boreholeList;
        let detailedViewId = this.state.detailedViewId;
        let analysisActiveIds = this.state.analysisActiveIds;

        checkedFiles.forEach(checkedFileId => {
            delete boreholeList[checkedFileId];
            if(checkedFileId === detailedViewId) {
                detailedViewId = "";
            }

            let analysisActiveIdsIndex = analysisActiveIds.findIndex(id => id === checkedFileId);

            if(analysisActiveIdsIndex !== -1) {
                analysisActiveIds.splice(analysisActiveIdsIndex, 1);
            }
        })

        this.setState({ 
            boreholeList: boreholeList, 
            checkedFiles: analysisActiveIds,
            analysisActiveIds: analysisActiveIds,
            detailedViewId: detailedViewId
        });
    }


    onWriteWhereChange = (e) => {
        this.setState({writeWhere: e.target.value});
    }

    onConvertLayersChange = (e) => {
        this.setState({convertLayersStandard: !this.state.convertLayersStandard});
    }

    render() {
        const boreholeList = this.state.boreholeList;

        const currentCoordinateSystem = this.state.currentCoordinateSystem;
        const loading = this.state.loading;

        const analysisActiveIds = this.state.analysisActiveIds;
        const updateAnalysisId = this.state.updateAnalysisId;

        const updateDetailedView = this.state.updateDetailedView;
        const detailedViewId = this.state.detailedViewId;

        const colorConfig = this.state.colorConfig;

        if(this.props.accountInfo){

            return (
                <div className="App">
                    <div className="header">
                        <div className="header-content">
                            {/* <button className="header-button" onClick={this.toggleSideBar}> <Tool size={30}/> </button> */}
                            <img alt="Geotolk" src={geotolkLogo} width="250px" align="center" id="logo" className="headerLogo"/>
                            <p className="header-text">Logget inn som {this.props.accountInfo.account.userName.toLowerCase()}</p>
                            <button className="header-logout" onClick={this.props.logout}>Logg ut</button>
                        </div>
                    </div>

                    <FilesList 
                    accountInfo={this.props.accountInfo}
                    boreholeList={boreholeList}
                    updateAnalysis={this.handleAnalysisChange}
                    updateDetailedView={this.handleDetailedViewChange}
                    loading={loading}
                    onLoadingChange={this.handleLoadingChange}
                    checkedFiles={this.state.checkedFiles}
                    allChecked={this.state.allChecked}
                    handleCheck={this.handleCheck}
                    handleCheckAll={this.handleCheckAll}
                    handleListToggle={this.handleListToggle}
                    toggleOpen={this.state.toggleOpen}
                    writeWhere={this.state.writeWhere}
                    onWriteWhereChange={this.onWriteWhereChange}
                    convertLayersStandard={this.state.convertLayersStandard}
                    handleDelete={this.handleDelete}
                    />
                <div className="content-container">
                <div className="step-one">
                    <div className="step-one-content">
                        <Introduction/>
                        <UploadFiles 
                            accountInfo={this.props.accountInfo}
                            boreholeList={boreholeList}
                            onUploadFile={this.handleUploadFile}
                            loading={loading}
                            onLoadingChange={this.handleLoadingChange}
                            colorConfig={colorConfig}
                            writeWhere={this.state.writeWhere}
                            onWriteWhereChange={this.onWriteWhereChange}
                            convertLayersStandard={this.state.convertLayersStandard}
                            onConvertLayersChange={this.onConvertLayersChange}
                            />
                    </div>
                </div>
       
                <DetailedView
                    detailedViewId={detailedViewId}
                    updateDetailedView={updateDetailedView}
                    setUpdateDetailedView={this.handleUpdateDetailedViewChange}
                    accountInfo={this.props.accountInfo}
                    boreholeList={boreholeList}
                    borehole={boreholeList[this.state.detailedViewId]}
                    onDeleteEntry={this.handleDeleteEntry}
                    loading={loading}
                    onLoadingChange={this.handleLoadingChange}
                    colorConfig={colorConfig}
                    onDynamicDataChange={this.handleBorholeChange}
                    toggleOpen={this.state.toggleOpen}/>

                <Analysis
                    detailedViewId={detailedViewId}
                    updateAnalysisId={updateAnalysisId}
                    setUpdateAnalysisId={this.handleUpdateAnalysisIdChange}
                    accountInfo={this.props.accountInfo}
                    boreholeList={boreholeList}
                    onDynamicDataChange={this.handleBorholeChange}
                    analysisActiveIds={analysisActiveIds}
                    changeAnalysisActiveIdsOrder={this.changeAnalysisActiveIdsOrder}
                    updateDetailedView={this.handleDetailedViewChange}
                    loading={loading}
                    onLoadingChange = {this.handleLoadingChange}
                    colorConfig={colorConfig}/>

                <MapDisplay 
                    boreholeList={boreholeList}
                    loading={loading}
                    onLoadingChange={this.handleLoadingChange}
                    updateDetailedView={this.handleDetailedViewChange}
                    checkedFiles={this.state.checkedFiles}
                    handleCheck={this.handleCheck}
                    currentCoordinateSystem={currentCoordinateSystem}
                    onCoordinateSystemChange={this.handleCoordinateSystemChange}
                    onCoordinatesChange={this.handleCoordinatesChange}
                    toggleOpen={this.state.toggleOpen}/>
            </div>
            </div>
            );
        }else{
        //Return empty because there are some lag when logging in/out
            return(
                <div/>
            )
        }
    }
}

export default App;
