import React from 'react';
import axios from 'axios';
import download from "downloadjs";
import ClipLoader from "react-spinners/ClipLoader";
import { InitializeLayerGraph } from './LayerGraph';
import './Graphs.css'

import * as am4core from "@amcharts/amcharts4/core";

export default class DynamicGraph extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            prevDetailedViewId: this.props.detailedViewId,
            containerId: "dynamicChart-" + this.props.detailedViewId,
            maxPressure: this.props.borehole.MAX_PRESSURE,
            activeIndex: 0,
            activeLayer: "",
            hoverDepth: "",
            hoverPressure: "",
            hoverFillColor: "",
            hoverType: "",
            hoverKote: ""
        }
    }

    componentDidMount() {
        this.initChart();
        this.initializeOverview();
    }

    componentWillUnmount() {
        if(this.chart) {
            this.chart.dispose();
        }
    }

    initializeOverview() {
        let firstLayer = this.props.dynamicData[0];

        this.setState({
            activeLayer: firstLayer.type,
            hoverDepth: firstLayer.start,
            hoverPressure: this.props.borehole.STATIC_DATA[0]["Trykk"],
            hoverFillColor: firstLayer.fillColor,
            hoverType: firstLayer.type,
            hoverKote: firstLayer.kote
        })
    }

    componentDidUpdate() {
        if(this.state.prevDetailedViewId !== this.props.detailedViewId) {
            this.chart.dispose();
            this.chart = null;
            this.initChart();
            this.initializeOverview();
            this.setState({ prevDetailedViewId: this.props.detailedViewId });
        } 
        if(this.props.updateDetailedView) {
            this.chart.invalidateData();
            this.pressureSeries.data = this.props.borehole.STATIC_DATA;
            this.props.resetUpdateDetailedView();
        }
    }

    downloadTLKFile = () => {
        let config = {
            headers: {
                "apikey": "wGn]Q^m,<.--cc*#-BYe?@V},VKXc8bp6e$",
                "user": this.props.accountInfo.account.userName,
                "Access-Control-Allow-Origin": "*"
            }
        }
        
        let tlk = this.props.borehole;
        this.props.onLoadingChange(true);

        axios.post("https://geotolk.azurewebsites.net/tlk", tlk, config)
            .then(res => {
                this.props.onLoadingChange(false);
                download(res.data.TLK_TEXT.DATA, res.data.TLK_TEXT.NAME, "text/plain");
        })
        .catch(res => {
            this.props.onLoadingChange(false);
            alert("Kan ikke laste ned TLK-fil");

        }
            
            )
    }

    setActiveLayer = (layer, updateData) => {  
        this.setState({ activeLayer: layer });

        if (updateData) {
            let dynamicData = this.props.dynamicData;
            dynamicData[this.state.activeIndex].type = layer;
            dynamicData[this.state.activeIndex].fillColor = this.props.colorConfig[layer];
            this.updateChartData();
        }
    }

    setActiveDepthRange = (index) => {
        let activeLayer = this.props.dynamicData[index].type;

        this.setState({ activeLayer: activeLayer, activeIndex: index });
    }

    setActiveIndex = (index) => {
        this.setState({ activeIndex: index });
    }

    updateDynamicData = (data) => {
        this.props.onDynamicDataListChange(data, this.props.detailedViewId);
    }

    setOverview = (depth, pressure, fillColor, type) => {
        this.setState({ hoverDepth: depth, hoverPressure: pressure, hoverFillColor: fillColor, hoverType: type });        
    }

    updateChartData = () => {
        this.sortData();
        this.chart.invalidateData();
        this.pressureSeries.data = this.props.borehole.STATIC_DATA;
        this.props.onDynamicDataChange(this.props.dynamicData, this.props.detailedViewId);
    }

    
    sortData = () => {
        this.chart.data.sort(function(a, b) {
            var aY = a.start;
            var bY = b.start;

            if (aY < bY) {
            return -1;
            }
            else if (aY === bY) {
            return 0;
            }
            else {
            return 1;
            }
        })

        for(var i = 0; i < this.chart.data.length - 1; i++) {
            this.chart.data[i].stop = this.chart.data[i + 1].start;
        }
    }

    initChart() {
        let colorConfig = this.props.colorConfig;
        let dynamicData = this.props.dynamicData;
        let setActiveDepthRange = this.setActiveDepthRange;
        let setOverview = this.setOverview;
        let updateChartData = this.updateChartData;


        let layerGraphComponents = InitializeLayerGraph(
            this.props.borehole.STATIC_DATA, 
            dynamicData, 
            this.state.containerId, 
            updateChartData);

        let chart = layerGraphComponents.chart;
        let pressureSeries = layerGraphComponents.pressureSeries;
        let layerBullet = layerGraphComponents.layerBullet;
        let xAxis = layerGraphComponents.xAxis;
        let yAxis = layerGraphComponents.yAxis;

        chart.svgContainer.htmlElement.style.width = "260px";
        chart.svgContainer.htmlElement.style.height = "700px";

        xAxis.title.dy = -18;
        yAxis.height = 620;
        
        this.chart = chart;
        this.pressureSeries = pressureSeries;

        var buttons = am4core.create("container", am4core.Container);
        buttons.layout = "horizontal";
        buttons.parent = layerBullet;
        buttons.paddingLeft = 10;
        buttons.dy = -4;
        buttons.propertyFields.hidden = "hideButtons";

        var deleteButton = new am4core.Button();
        deleteButton.propertyFields.width = "deleteButtonWidth";
        deleteButton.height = 25;
        deleteButton.background.fill = "white";
        var deleteHoverState = deleteButton.background.states.getKey("hover");
        deleteHoverState.properties.fill = am4core.color("grey").lighten(0.5);
        deleteButton.parent = buttons;
        deleteButton.cursorOverStyle = am4core.MouseCursorStyle.pointer

        var addButton = new am4core.Button();
        addButton.width = 25;
        addButton.height = 25;
        addButton.background.fill = "white";
        var addHoverState = addButton.background.states.getKey("hover");
        addHoverState.properties.fill = am4core.color("grey").lighten(0.5);
        addButton.parent = buttons;
        addButton.cursorOverStyle = am4core.MouseCursorStyle.pointer

        var plus = new am4core.Sprite();
        plus.path = "M 12.00,5.00 C 12.00,5.00 12.00,19.00 12.00,19.00M 5.00,12.00 C 5.00,12.00 19.00,12.00 19.00,12.00";
        plus.fillOpacity = 0;
        plus.stroke = "#2c6384";
        plus.strokeWidth = 2;
        plus.group.node.setAttribute("stroke-linecap", "round");
        plus.group.node.setAttribute("stroke-linejoin", "round");
        plus.rotation = 180;
        plus.width = 8;
        plus.height = 8;
        plus.dx = -10.5;
        plus.dy = -7;
        plus.interactionsEnabled = false;
        plus.parent = addButton;

        var trash = new am4core.Sprite();
        trash.path = `M 3.00,6.00
                C 3.00,6.00 5.00,6.00 5.00,6.00
                    5.00,6.00 21.00,6.00 21.00,6.00M 19.00,6.00
                C 19.00,6.00 19.00,20.00 19.00,20.00
                    19.00,21.10 18.10,22.00 17.00,22.00
                    17.00,22.00 7.00,22.00 7.00,22.00
                    5.90,22.00 5.00,21.10 5.00,20.00
                    5.00,20.00 5.00,6.00 5.00,6.00M 8.00,6.00
                C 8.00,6.00 8.00,4.00 8.00,4.00
                    8.00,2.90 8.90,2.00 10.00,2.00
                    10.00,2.00 14.00,2.00 14.00,2.00
                    15.10,2.00 16.00,2.90 16.00,4.00
                    16.00,4.00 16.00,6.00 16.00,6.00`;
        trash.fillOpacity = 0;
        trash.stroke = "#2c6384";
        trash.strokeWidth = 2;
        trash.group.node.setAttribute("stroke-linecap", "round");
        trash.group.node.setAttribute("stroke-linejoin", "round");
        trash.width = 8;
        trash.height = 8;
        trash.dx = -12;
        trash.dy = -11;
        trash.interactionsEnabled = false;
        trash.parent = deleteButton;

        var cursorPosition = {
            x: null,
            y: null
        }

        chart.cursor.events.on("cursorpositionchanged", function(ev) {
            cursorPosition.x = xAxis.positionToValue(xAxis.toAxisPosition(ev.target.xPosition));
            cursorPosition.y = yAxis.positionToValue(yAxis.toAxisPosition(ev.target.yPosition));

            let depth = cursorPosition.y.toFixed(2);
            let pressure = cursorPosition.x.toFixed(0);

            let fillColor = "";
            let type = "";

            for(var i = 0; i < chart.data.length; i++) {
                if(chart.data[i].start < cursorPosition.y && chart.data[i].stop > cursorPosition.y) {
                    fillColor = chart.data[i].fillColor;
                    type = chart.data[i].type;
                    break;
                }
            }

            setOverview(depth, pressure, fillColor, type);
        })

        chart.plotContainer.events.on("hit", function(ev) {
            let yValue = cursorPosition.y;
            let index = 0;
            
            for(var i = 0; i < chart.data.length; i++) {
                if(chart.data[i].start < yValue && chart.data[i].stop > yValue) {
                    index = i;
                    break;
                }
            }

            setActiveDepthRange(index);
        })

        deleteButton.events.on("down", function(event) {
            var dataItem = event.target.dataItem;
            var dataContext = dataItem.dataContext;
            var index = chart.data.indexOf(dataContext);
            
            if(index < dynamicData.length - 1) {
                chart.data.splice(index, 1);
                updateChartData();
            }
        });
        
        addButton.events.on("down", function(event) {
            if (chart.cursor.visible) {
                var dataContext = event.target.dataItem.dataContext;
                var point = am4core.utils.documentPointToSprite(event.pointer.point, chart.seriesContainer);
                var index = chart.data.indexOf(dataContext);

                var minValue = yAxis.yToValue(point.y);
                var maxValue = chart.data[index + 1].start

                var firstPoint = JSON.parse(JSON.stringify(chart.data[0]));
                var newPoint = firstPoint;

                newPoint.draggable = true;
                newPoint.start = (minValue + maxValue) / 2;
                newPoint.stop = maxValue;
                newPoint.type = "Udrenert";
                newPoint.fillColor = colorConfig[newPoint.type];
                newPoint.deleteButtonWidth = 25;

                chart.data.push(newPoint);
                
                updateChartData();
            }
        })
    }

    render() {
        return (
            <div>
                <div className="graph-container">
                    <div className="dynamicGraph" id={this.state.containerId}>
                    </div>
                    <div className="graph-labels">
                        <div id="layer-overview" className="layer-overview">
                            <p className="layer-depth">
                                Kote: {this.state.hoverKote} m
                            </p>
                            <p className="layer-depth">
                                Dybde: {this.state.hoverDepth} m
                            </p>
                            <p className="layer-pressure">
                                Matekraft: {(this.state.hoverPressure / 1000).toFixed(2)} kN
                            </p>
                            <div id="layer-type" className="layer-type">
                                <div className="labelBox labelBox-overview" style={{background: this.state.hoverFillColor}}></div><strong>{this.state.hoverType}</strong>
                            </div>                
                        </div>
                        <div id="edit-layer" className="edit-layer">
                            <p className="edit-layer-title">Endre lagtype</p>
                            <select id="edit-layer-depth" className="edit-layer-depth" value={this.state.activeIndex} onChange={(event) => this.setActiveDepthRange(event.target.value)}>
                                {
                                    this.props.dynamicData.map((dataPoint, index) => (
                                        <option value={index} hidden={index === this.props.dynamicData.length - 1} key={index}>
                                            {dataPoint.start.toFixed(2)} m - {dataPoint.stop.toFixed(2)} m
                                        </option>
                                        ))
                                }
                            </select>
                            <select id="edit-layer-select" className="edit-layer-select" value={this.state.activeLayer} onChange={(event) => this.setActiveLayer(event.target.value, true)}>
                                { 
                                    Object.entries(this.props.colorConfig).map(entry => (
                                        <option value={entry[0]} key={entry[0]}>
                                            {entry[0]}
                                        </option>
                                        )) 
                                }
                            </select>
                            <ul className="depth-range-layers">
                                {
                                    this.props.dynamicData.map((dataPoint, index) => (
                                        <li style={{ display: index === this.props.dynamicData.length - 1 ? "none" : "block"}} key={index}>
                                            <div className="labelBox" style={{ background: dataPoint.fillColor }}></div>{dataPoint.start.toFixed(2)} m - {dataPoint.stop.toFixed(2)} m: {dataPoint.type}   
                                        </li>
                                    ))
                                }
                            </ul>
                            <button disabled={this.props.loading} className="graph-button" onClick={() => { this.downloadTLKFile() }}>
                            {
                                this.props.loading ? <ClipLoader size={25} color={"#2b5279"} loading={this.props.loading} /> : "Last ned TLK-fil"
                            }
                            </button>
                        </div>   
                    </div>
                </div>
            </div>   
        )
      }

  }