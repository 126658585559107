import React from "react";
import axios from "axios";
import "./FilesList.css";
import cptIcon from "../cpt.svg";
import prvIcon from "../prv.svg";
import totIcon from "../tot.svg";
import Checkbox from "@material-ui/core/Checkbox";
import download from "downloadjs";
import _ from "lodash";

export default class FilesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilesList: true,
      theSelectedFile: "",
      checkedFiles: this.props.checkedFiles,
      allChecked: this.props.allChecked,
      boreholeList: this.props.boreholeList
    };
    // this.toggleFilesList = this.toggleFilesList.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.handleCheck = this.props.handleCheck.bind(this);
  }

  updateAnalysis = () => {
    let activeIndexes = this.props.checkedFiles;

    this.props.updateAnalysis(activeIndexes);
    let elem = document.getElementById("analysis");
    if (elem != null) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
}

    handleCSVDownload = () => {
        if (this.props.checkedFiles.length > 0){
            let config = {
                headers: {
                    "apikey": "wGn]Q^m,<.--cc*#-BYe?@V},VKXc8bp6e$",
                    "user": this.props.accountInfo.account.userName,
                    "Access-Control-Allow-Origin": "*",
                    "convertLayersStandard": Number(this.props.convertLayersStandard)
                }
            };
            this.props.onLoadingChange(true);
            let data = new FormData();
            this.props.checkedFiles.forEach(element => {
                let currentBorehole = this.props.boreholeList[element];
                let TLK = _.cloneDeep(currentBorehole.DYNAMIC_DATA);
                TLK.pop();
                data.append(currentBorehole.BORHOLE_NAME, JSON.stringify({"metadata":currentBorehole.SND_METADATA, "TLK": TLK}));
            });
            let URL = "https://geotolk.azurewebsites.net/export";
            axios.post(URL, data, config).then(
                res => {
                    let locationCSV = res.data.LOCATION_CSV;
                    let interpretCSV = res.data.INTERPRETATION_CSV

                    download(locationCSV, "CSV_1.csv", "text/csv");
                    download(interpretCSV, "CSV_2.csv", "text/csv");
                }
            ).catch(res => {
                console.log("Klarte ikke eksportere CSV");
            })
            .finally(res => {
                this.props.onLoadingChange(false);
            })
        }
        else {
            alert("Trenger minst en fil");
        }
    }

    handleTLKDownload = () => {
        if (this.props.checkedFiles.length > 0){
            let JSZip = require("jszip");
            let zip = new JSZip();
            let config = {
                headers: {
                    "apikey": "wGn]Q^m,<.--cc*#-BYe?@V},VKXc8bp6e$",
                    "user": this.props.accountInfo.account.userName,
                    "Access-Control-Allow-Origin": "*",
                    "writeWhere": this.props.writeWhere
                }
            };
            this.props.onLoadingChange(true);
            this.successfulFiles = 0;
            this.failedFiles = 0;

            for (let key in this.props.checkedFiles){
                let boreholeID = this.props.checkedFiles[key];
                let currentBorehole = this.props.boreholeList[boreholeID];
                axios.post("https://geotolk.azurewebsites.net/tlk", currentBorehole, config)
                .then(
                    res => {
                        let filename = res.data.TLK_TEXT.NAME;
                        let tlk = res.data.TLK_TEXT.DATA;
                        zip.file(filename, tlk);
                        this.successfulFiles += 1;
                    }
                )
                .catch(
                    res => {
                        this.failedFiles += 1;
                    }
                )
                .finally(
                    res => {
                        if (this.successfulFiles + this.failedFiles === this.props.checkedFiles.length){
                            zip.generateAsync({type: "blob"})
                            .then(
                                (content) => {
                                    download(content, "TLK_FILES.zip");
                               }
                            )
                        }
                        this.props.onLoadingChange(false);

                    }
                )
            }
        }
        else{
            alert("Trenger minst en fil")
        }
    }

  scrollToMapView = () => {
    let element = document.getElementById("map");
    if (element != null) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  selectFile = (selectedFile) => {
    this.setState({ theSelectedFile: selectedFile });

    this.props.updateDetailedView(selectedFile);
    let elem = document.getElementById("detailedView");
    if (elem != null) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
  };

//   toggleFilesList() {
//     this.setState((prevState) => ({
//       showFilesList: !prevState.showFilesList,
//     }));
//     this.props.handleListToggle(this.state.showFilesList);
//   }


  render() {
    let files;
    let text;
    // let toggle;
    let checkAll;
    let deleteFiles;
    if (this.props.boreholeList) {
      text = <span />;
      deleteFiles = (
      <button className="deleteButton" onClick={this.props.handleDelete}>
          Slett filer
      </button>)
      checkAll = (
        <span className="checkbox-checkAll-container">
          <Checkbox
            className="checkbox-checkAll"
            name="checkAll"
            color="primary"
            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
            labelplacement="end"
            label="Check all"
            onChange={(e) => this.props.handleCheckAll(e)}
          />
        </span>
      );
      files = Object.entries(this.props.boreholeList).map(([key, borhole]) => (
          <div className="file" key={"file-" + key}>
            <Checkbox
              className="checkbox"
              color="primary"
              inputProps={{ "aria-label": "uncontrolled-checkbox" }}
              label={key}
              key={key}
              onChange={(e) => this.handleCheck(e, key)}
              checked={this.props.checkedFiles.includes(key)}
            />
            <div className="fileInfo" onClick={() => this.selectFile(key)} key={"fileInfo" + key}>
              <div
                className="fileName"
                style={
                    key === this.state.theSelectedFile
                    ? { fontWeight: "bold" }
                    : { fontWeight: "normal" }
                }
              >
                {borhole.BORHOLE_NAME}
              </div>
              <img
                src={totIcon}
                alt={"totalsondering ikon"}
                className="totIcon"
              />
              <img
                src={cptIcon}
                alt={"cpt ikon"}
                className="cptIcon"
                style={borhole.CPT ? { width: 15 } : { width: 0 }}
              />
              <img
                src={prvIcon}
                alt={"prv ikon"}
                className="prvIcon"
                style={borhole.PRV ? { width: 15 } : { width: 0 }}
              />
            </div>
          </div>
      ));
    } else {
      files = <span />;
      text = <p className="noFiles">Ingen filer er opplastet</p>;
    }

    if (this.state.showFilesList === true) {
    //   toggle = <span>&#9664;</span>;
    } else {
    //   toggle = <span>&#9654;</span>;
    }

    return (
      <div
        className="filesList"
        // style={
        //   this.state.showFilesList ? { marginLeft: 0 } : { marginLeft: -260 }
        // }
      >
        {/* <span className="closeFiles" onClick={this.toggleFilesList}>
          {toggle}
        </span> */}
        <span className="showButtonsContainer">
          <button
            className="tolkningsplottButton"
            onClick={this.updateAnalysis}
          >
            Vis Tolkningsplott
          </button>
          <button className="mapButton" onClick={this.scrollToMapView}>
            Gå til kartvisning
          </button>
          </span>
          <span className="downloadButtonsContainer">
          <button className="csvButton" onClick={this.handleCSVDownload}>
            Last ned CSV-filer
          </button>
          <button className="tlkButton" onClick={this.handleTLKDownload}>
            Last ned TLK-filer
          </button>
        </span>
        <div className="markAndDelete">{checkAll}<span className="numberOfFiles">{this.props.checkedFiles.length} markert av {Object.keys(this.props.boreholeList).length}</span><span className="deleteButton-container">{deleteFiles}</span></div>
        <div className="files">
          {files}
          {text}
        </div>
      </div>
    );
  }
}
