
import React from 'react';
import Collapsible from 'react-collapsible'
import TotalSoundingGraphs from '../Graphs/TotalSoundingGraphs'
import DynamicGraph from '../Graphs/DynamicGraph'
import CPTGraph from '../Graphs/CPTGraph'
import PredictedLayersGraph from '../Graphs/PredictedLayersGraph'
import './DetailedView.css'
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import Tooltip from '@material-ui/core/Tooltip';

export default class DetailedView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCPT: false,
            showProve: false,
        }
    }

    onDynamicDataChange = (newDynamicData, boreholeId) => {
        let boreholeList = this.props.boreholeList;
        boreholeList[boreholeId].DynamicData = newDynamicData;
        this.props.onDynamicDataChange(boreholeList, boreholeId, false);
    }

    resetUpdateDetailedView = () => {
        this.props.setUpdateDetailedView(false);
    }

    toggleCPT = () => {
        let showCPT = !this.state.showCPT;
        this.setState({showCPT: showCPT});
    }
    
    toggleProve = () => {
        let showProve = !this.state.showProve;
        this.setState({showProve: showProve});
    }

    render() {
        let totalSoundingGraphs = <div/>;
        let dynamicGraph = <div/>;
        let predictedLayersGraph = <div/>;
        let cptGraph = <div/>;
        let proveGraph = <div/>;
        let toggleCPTButton = <div/>;
        let togglePRVButton = <div/>;

        if (this.props.borehole) {
            totalSoundingGraphs = <TotalSoundingGraphs 
                    staticData = {this.props.borehole.STATIC_DATA}
                    detailedViewId = {this.props.detailedViewId}
                    colorConfig = {this.props.colorConfig} />
            dynamicGraph =  <DynamicGraph
                    accountInfo = {this.props.accountInfo}
                    resetUpdateDetailedView = {this.resetUpdateDetailedView}
                    updateDetailedView = {this.props.updateDetailedView}
                    onDynamicDataChange =  {this.onDynamicDataChange}
                    onLoadingChange = {this.props.onLoadingChange} 
                    borehole = {this.props.borehole}
                    dynamicData = {this.props.borehole.DYNAMIC_DATA}
                    detailedViewId = {this.props.detailedViewId}
                    colorConfig = {this.props.colorConfig}
                    loading = {this.props.loading}/>
            predictedLayersGraph = <PredictedLayersGraph
                    staticData = {this.props.borehole.STATIC_DATA}
                    colorConfig = {this.props.colorConfig} />
        }

        if (this.props.borehole?.CPT && this.props.borehole?.CPT.length > 0) {
            cptGraph = 
                <div className="cpt" style={{ display: (this.state.showCPT ? 'block' : 'none') }}>
                    <h3 className="graph-title">CPT</h3>
                    <CPTGraph borehole={this.props.borehole} />
                </div>
            toggleCPTButton = 
                <button type="submit" className="toggle-button" onClick={() => this.toggleCPT()}>
                    Vis/Skjul CPT
                </button>
        }

        if(this.props.borehole?.PRV_PLOT && this.props.borehole?.PRV_PLOT.IMAGE) {
            proveGraph = 
            <div className="prove" style={{ display: (this.state.showProve ? 'block' : 'none') }}>
                <h3 className="graph-title graph-title-prøveserie">Prøveserie</h3>
                <div className="prove-image">
                    <img src={`data:image/png;base64,${this.props.borehole.PRV_PLOT.IMAGE}`} alt="Prøveserie"  height="672px" width="1008px" />  
                </div>

            </div>
            togglePRVButton =
                <button type="submit" className="toggle-button" onClick={() => this.toggleProve()}>
                    Vis/Skjul Prøveserie
                </button>
        }
        return (
            <div id="detailedView" className="detailedView">
                <h1 className="detailedView-h1">Detaljert visning
                <Tooltip placement="top-start" title="Her vil du se detaljert visning for valgt borhull. Velg borhull i listen til venstre." arrow>
                <HelpOutlineRoundedIcon className="helpIcon helpIcon-plot" style={{ color: "#2b5279" }}/>
                </Tooltip>
                </h1>
                <div className="collapsible-entry">
                    {this.props.borehole ? 
                        <Collapsible trigger={this.props.borehole?.BORHOLE_NAME} open={true}>
                            <div className="collapse_body">
                                <div className="toggle-buttons">
                                    {toggleCPTButton}
                                    {togglePRVButton}
                                </div>
                                <div className="graphs">
                                    {cptGraph}

                                    <div className="totalsondering">
                                        <h3 className="graph-title graph-title-totalsondering">Totalsondering</h3>
                                        {totalSoundingGraphs}                          
                                    </div>                     
                                    <div>
                                        <h3 className="graph-title">Lagdeling</h3>
                                        <div className="layer-graphs">
                                            {predictedLayersGraph}
                                            {dynamicGraph}
                                        </div>
                                    </div>
                                    {proveGraph}
                                </div>
                            </div>
                        </Collapsible>
                        : <div/>                
                }    
                </div>
            </div>
        );
    }
}