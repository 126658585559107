import React from 'react';
import './Graphs.css'

import {
    Line,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid
  } from "recharts";

export default class CPTGraph extends React.PureComponent {

    render() {
        const BasicTooltip = ({ active, payload, label }) => {
            if(active) {
                return <div className="tooltip">
                        <p>{`${label} m:`}</p>
                        <ul className="tooltip-list">
                            {payload.map((entry, index) => {
                                return <li 
                                        className="tooltip-list-item" 
                                        key={index} 
                                        style={{ color: entry["color"] }}>
                                    {`${entry["dataKey"].charAt(0).toUpperCase() + entry["dataKey"].slice(1)}: ${entry["value"]}`}
                                    </li>
                            })}
                        </ul>
                    </div>
            }
            return null
        }

        let staticData = this.props.borehole.STATIC_DATA;
        let cptData =  this.props.borehole.CPT;
        
        let dataMin = staticData[0]["Dybde"];
        let dataMax = staticData[staticData.length - 1]["Dybde"];

        let large_width = 120;

        let bottomMargin = 50;
        let tickCount = 10;

        return (
        <div className="staticGraphs">
                        <div className="content">
                <LineChart 
                    layout="vertical"
                    height={700} width={large_width + 20} 
                    data={cptData}
                    margin={{ bottom: bottomMargin, left: 10 }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis 
                    type="number" 
                    label={{value: "Spissmotstand [kPa]", fontWeight: "bold", dy: 20, fill: "#B83732" }} />
                <YAxis 
                    dataKey="dybde" 
                    type="number" 
                    tick={false}
                    width={10}
                    tickCount={tickCount}
                    domain={[dataMin, dataMax]} />
                <Tooltip content={<BasicTooltip/>}/>
                <Line isAnimationActive={false} dataKey="spisstrykk" stroke="#39A1E6" dot={false}/>
                </LineChart>
            </div>
           <div className="content">
               <LineChart 
                    layout="vertical"
                    height={700} width={large_width + 35} 
                    data={cptData}
                    margin={{ bottom: bottomMargin, left: 25 }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis 
                    type="number" 
                    label={{value: "Sidefriksjon [kPa]", fontWeight: "bold", dy: 20, fill: "#39A1E6" }} />
                <YAxis 
                    dataKey="dybde" 
                    type="number"
                    width={10}
                    tickCount={tickCount}
                    domain={[dataMin, dataMax]} />
                <Tooltip content={<BasicTooltip/>}/>
                <Line isAnimationActive={false} dataKey="friksjon" stroke="#B83732" dot={false}/>
                </LineChart>
            </div>
            <div className="content">
                <LineChart 
                    layout="vertical"
                    height={700} width={large_width + 20 } 
                    data={cptData}
                    margin={{ bottom: bottomMargin, left: 10 }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis 
                    type="number" 
                    label={{value: "Poretrykk [kPa]", fontWeight: "bold", dy: 20, fill: "#B83732" }} />
                <YAxis 
                    dataKey="dybde" 
                    type="number" 
                    width={10}
                    tick={{ fontSize: 0 }}
                    tickCount={tickCount}
                    domain={[dataMin, dataMax]} />
                <Tooltip content={<BasicTooltip/>}/>
                <Line isAnimationActive={false} dataKey="poretrykk" stroke="#B83732" dot={false}/>
                </LineChart>
           </div> 
        </div>
        )
    }
}