import React from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid
  } from "recharts";
  import './Graphs.css'

export default class PredictedLayersGraph extends React.PureComponent {
    render() {
        const PredictionClassesTooltip = ({ active, payload, label }) => {
            if(active) {
                return <div className="tooltip">
                    <p>{`${label} m:`}</p>
                    <ul className="tooltip-list">
                        {payload.map((entry, index) => {
                            return <li 
                                    className="tooltip-list-item" 
                                    key={index}>
                                <div className="labelBox" style={{ background: entry["fill"] }}></div>
                                {`${entry["dataKey"]}: ${entry["value"]} %`}
                                </li>
                        })}
                    </ul>
                </div>
            }
            return null
        }

        return(
            <div className="predicted-layers-distribution">
                        <div className="content c-white">
                            <AreaChart 
                                height={700} width={ 200 }
                                layout="vertical"
                                data={this.props.staticData}
                                margin={{ bottom: 50, left: 40 }}
                                isAnimationActive={false}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis 
                                    type="number"
                                    tick={false}
                                    label={{ value: "Predikert laginndeling ", fontWeight: "bold", dy: 10 }}
                                    domain={['dataMin', 'dataMax']}
                                    unit="%"
                                />
                                <YAxis
                                    type="number"
                                    dataKey="Dybde"

                                    tick={{ fontSize: 0 }}
                                    tickCount={10}
                                    width={10}
                                    domain={['dataMin', 'dataMax']}
                                />
                                <Tooltip content={<PredictionClassesTooltip/>}/>
                                {
                                    Object.entries(this.props.colorConfig).map(([key, value]) => (
                                        <Area type='monotone' fillOpacity="1" stackId="1"
                                        dataKey={key}
                                        activeDot={false} 
                                        fill={value}
                                        stroke={value}
                                        key={key} />                                            
                                    ))
                                }
                            </AreaChart>
                        </div>
                    </div>
        )
    }


}