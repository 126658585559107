// authProvider.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
 
const config = {
  auth: {
    authority: "https://login.microsoftonline.com/ecc26098-7721-4a2f-8db0-64e8eb89d129",
    clientId: '9d21607b-935a-4fb7-ba89-07b8760fd755',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: true,
    // After being redirected to the "redirectUri" page, should user
    //be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true 
  }
};
 
export const authenticationParameters = {
  scopes: [
    "https://graph.microsoft.com/.default"
  ]
}

export const authenticationParametersGraph = {
    scopes: [
    'openid'
    ]
}
// Options
export const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)